<template>
  <v-col
    ref="customerInfoPanel"
    cols="12"
    class="px-4"
    id="customerInfoPanelId"
  >
    <div class="text-subtitle-1 primary--text font-weight-bold mb-2">
      Información de envío
    </div>
    <div
      v-if="pickUpPoint"
      class="text-subtitle-1 primary--text font-weight-bold mb-2"
    >
      Punto de entrega: {{ pickUpPoint.name }}
    </div>
    <v-container>
      <base-outlined-block>
        <v-text-field
          class="pb-4"
          v-if="isKioskModeEnabled"
          v-model="payload.name"
          :loading="loading"
          :rules="emptyRule"
          dense
          required
          flat
          hide-details
          label="Nombre"
          rounded
          filled
          @focus="scrollToEnd"
        />
        <v-text-field
          v-if="isValidatorModeEnabled"
          class="pb-4"
          v-model.trim="payload.email"
          :loading="loading"
          :rules="emptyRule"
          dense
          required
          flat
          hide-details
          label="Email"
          rounded
          filled
        />
        <v-text-field
          v-if="isWebOrdersModeEnabled"
          class="pb-4"
          v-model="payload.name"
          :loading="loading"
          :rules="emptyRule"
          dense
          required
          flat
          hide-details
          label="Nombre"
          rounded
          filled
        />
        <v-text-field
          v-if="isWebOrdersModeEnabled"
          class="pb-4"
          v-model="payload.phone"
          @keypress="onlyNumbers"
          :loading="loading"
          :rules="emptyRule"
          dense
          required
          flat
          hide-details
          label="Telefono"
          rounded
          filled
          pattern="[0-9]*"
        />
        <vue-google-autocomplete
          v-if="isWebOrdersModeEnabled && hasToShowAddressConfig"
          id="map"
          placeholder="Dirección"
          v-on:placechanged="getAddressData"
          v-on:inputChange="onAddressInputChange"
          :item-text="(item) => item.formatted_address"
          :item-value="(item) => item.place_id"
          classname="address-autocomplete"
          ref="address"
          :country="getCountry"
          @blur="validateAddressInput"
        ></vue-google-autocomplete>
        <v-text-field
          v-if="isWebOrdersModeEnabled && hasToShowAddressConfig"
          class="pb-4"
          v-model="payload.addressExtraInfo"
          :loading="loading"
          dense
          flat
          hide-details
          label="Depto / Piso / Timbre"
          rounded
          filled
        />
        <v-text-field
          v-if="isWebOrdersModeEnabled"
          class="pb-4"
          v-model.trim="payload.email"
          :loading="loading"
          :rules="emptyRule"
          dense
          required
          flat
          hide-details
          label="Email"
          rounded
          filled
        />
        <div v-if="hasToShowDeliveryZoneError" class="text-start error--text">
          Esta dirección está fuera del área de entrega del local.
        </div>
        <div v-if="hasToShowListError" class="text-start error--text">
          Por favor, selecciona una dirección de la lista para continuar
        </div>
        <div
          v-if="
            !hasToShowListError &&
            !hasToShowDeliveryZoneError &&
            hasToShowStreetNumberError &&
            getDeliveryMethod == 'delivery'
          "
          class="text-start error--text"
        >
          Completa la dirección con la altura correspondiente a la calle
          ingresada.
        </div>
      </base-outlined-block>
    </v-container>
  </v-col>
</template>
<script>
import shopUtils from "@/mixins/shopUtils";
import VueGoogleAutocomplete from "vue-google-autocomplete";
import { getCountryCode } from "@/utils/country.js";
export default {
  mixins: [shopUtils],
  components: {
    VueGoogleAutocomplete,
  },
  data: () => {
    return {
      loading: false,
      validForm: true,
      emptyRule: [(v) => !!v || "Necesario"],
      rawAddressData: null,
      payload: {
        name: null,
        address: null,
        phone: null,
        email: null,
        addressExtraInfo: null,
      },
      hasToShowAddressConfig: false,
      hasToShowDeliveryZoneError: false, //mensajes de error
      hasToShowListError: false,
      hasToShowStreetNumberError: false,
      flagInputChange: false, //Se usa para que no se ejecute onAddressInputChange apenas confirmas una direccion de la lista
      emptyAddressInput: false,
    };
  },
  methods: {
    getCountryCode,
    onlyNumbers(event) {
      if (event.keyCode < 48 || event.keyCode > 57) {
        event.preventDefault();
      }
    },
    scrollToEnd() {
      window.scrollBy(0, 200);
      //setTimeout(this.scrollToEnd, 1);
    },
    initiallizeShippingInformation() {
      setTimeout(() => {
        this.payload = this.$store.state.deliveryCart.shippingInformation;
        this.payload.address = null;
        this.parseAddress();
        this.updateShippingInformation();
        if (this.$refs.address) {
          this.$refs.address.clear();
        }
        if (!this.payload.email) {
          this.payload.email = window.localStorage.getItem("email") ?? "";
        }
      }, 2000);
    },
    onAddressInputChange(key) {
      //Se ejecuta cuando se escribe o borra un caracter en el input del address
      if (!this.flagInputChange) {
        //Borro la direccion para deshabilitar el boton
        this.payload.address = null;
        this.updateShippingInformation();
        this.emptyAddressInput = key.newVal.length == 0 ? true : false;
        //borro todos los errores que habia
        this.hasToShowDeliveryZoneError = false;
        this.hasToShowListError = false;
        this.hasToShowStreetNumberError = false;
      }
    },
    setFlagInputChange() {
      //Funcion que pone el flagInputChange en true por 100mS para que cuando seleccione una opcion del autocomplete no entre a onAddressInputChange
      this.flagInputChange = true;
      setTimeout(() => {
        this.flagInputChange = false;
      }, 100);
    },
    getAddressData(data) {
      //Se ejecuta cuando se selecciona una opcion del autocomplete
      this.setFlagInputChange();
      this.hasToShowListError = false; //No muestro el error porque seleccione una opcion
      if (data?.route == false) {
        this.payload.address == null;
      } else {
        this.rawAddressData = data;
        this.validateDeliveryZone(); //Me fijo si esa address esta dentro de la zona
      }
    },
    parseAddress() {
      if (!this.rawAddressData) return;
      this.payload.address = `${this.rawAddressData.route} ${
        this.rawAddressData.street_number ?? ""
      } ${this.payload.addressExtraInfo ?? ""} ${
        this.rawAddressData.locality ?? ""
      } ${this.rawAddressData.country ?? ""}`;
    },
    updateShippingInformation() {
      this.$store.dispatch(
        "deliveryCart/updateShippingInformation",
        this.payload
      );
      if (!this.payload.email || this.payload.email == "null") {
        this.payload.email =
          window.localStorage.getItem("email") ?? this.payload.email;
      } else {
        window.localStorage.setItem("email", this.payload.email ?? "");
      }
    },
    validateDeliveryZone() {
      var deliveryZoneConfig = this.$store.state.menu.profile.deliveryZone;
      if (deliveryZoneConfig == undefined || deliveryZoneConfig == null) {
        this.parseAddress();
        this.updateShippingInformation();
        return;
      }
      //Obtengo las coordenadas y la config de la zona
      var userCoords = {
        lat: this.rawAddressData.latitude,
        lng: this.rawAddressData.longitude,
      };

      if (deliveryZoneConfig.isRadialZoneSelected) {
        //Si la zona radial estaba habilitada calculo la distancia entre las coordenadas del usuario y el centro del local
        var distance = google.maps.geometry.spherical.computeDistanceBetween(
          userCoords,
          deliveryZoneConfig.addressCoords
        );
        if (distance <= deliveryZoneConfig.radius) {
          if (this.rawAddressData.street_number == undefined) {
            //si no puso numero de calle
            this.hasToShowStreetNumberError = true;
            this.payload.address = null;
            this.updateShippingInformation();
            return;
          }
          //Guardo la info
          this.parseAddress();
          this.updateShippingInformation();
          return;
        }
        //Activo el error y borro el address
        this.hasToShowDeliveryZoneError = true;
        this.payload.address = null;
        this.updateShippingInformation();
        return;
      }
      //Si la zona es personalizada
      var polygon = new google.maps.Polygon({
        paths: deliveryZoneConfig.polygonCoords,
      });
      var isUserInPolygon = google.maps.geometry.poly.containsLocation(
        userCoords,
        polygon
      );
      if (isUserInPolygon) {
        if (!this.rawAddressData.street_number) {
          this.hasToShowStreetNumberError = true;
          this.payload.address = null;
          this.updateShippingInformation();
          return;
        }
        //Guardo el address
        this.parseAddress();
        this.updateShippingInformation();
        return;
      }
      //Borro el address y muestro error
      this.hasToShowDeliveryZoneError = true;
      this.payload.address = null;
      this.updateShippingInformation();
      return;
    },
    validateAddressInput() {
      //Se ejecuta cuando estaba escribiendo en el input del address y toque en otro lado
      if (this.payload.addressCoords == null && !this.emptyAddressInput) {
        this.hasToShowListError = true;
      } else this.hasToShowListError = false;
    },
  },
  computed: {
    getCountry() {
      return this.getCountryCode();
    },
    isValidatorModeEnabled() {
      return (
        this.$store.state.menu.profile.operationMode == 2 &&
        !this.isKioskModeEnabled
      );
    },
    getDeliveryMethod() {
      return this.$store.getters["deliveryCart/getDeliveryMethod"];
    },
  },
  watch: {
    payload: {
      deep: true,
      handler() {
        this.updateShippingInformation();
      },
    },
    getDeliveryMethod() {
      //si cambio el metodo
      if (this.getDeliveryMethod == "pickup") {
        //Si era pickup borro todos los errores referidos al address
        this.hasToShowDeliveryZoneError = false;
        this.hasToShowListError = false;
        this.hasToShowStreetNumberError = false;
        this.hasToShowAddressConfig = false; //no tengo que mostrar los campos de address
        //borro todo lo referido a la direccion
        this.payload.address = null;
        this.payload.addressExtraInfo = null;
        this.rawAddressData = null;
        //updateo el carro
        this.updateShippingInformation();
      } else {
        this.hasToShowAddressConfig = true; //si era delivery muestro la configuracion del address
      }
    },
  },
  mounted() {
    this.hasToShowAddressConfig = this.getDeliveryMethod === "delivery";
    this.initiallizeShippingInformation();
  },
};
</script>


<style scoped>
.address-autocomplete {
  width: 100%;
  display: flex;
  padding: 0 24px;
  margin-bottom: 16px;
  align-items: stretch;
  min-height: 52px;
  border-bottom-left-radius: 28px;
  border-bottom-right-radius: 28px;
  border-top-right-radius: 28px;
  border-top-left-radius: 28px;
  outline: none;
  background-color: rgba(0, 0, 0, 0.06);
}
</style>
